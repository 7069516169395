.App-header {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
    font-weight: bold;
    color: #343a40;
}

.nav-link {
    color: #343a40;
}

    .nav-link:hover {
        color: #007bff;
    }
