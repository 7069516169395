table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

.currency-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    padding: 1rem;
}

h1, h2 {
    padding: 8px;
}

.currency-card {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 1rem;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

    .currency-card:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    .currency-card h3 {
        margin: 0.5rem 0;
        font-size: 1.25rem;
        color: #333;
    }

    .currency-card p {
        margin: 0.5rem 0;
        font-size: 1rem;
        color: #666;
    }

    .currency-card button {
        background: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        cursor: pointer;
        font-size: 1rem;
        transition: background 0.3s ease;
    }

        .currency-card button:hover {
            background: #0056b3;
        }

        .header {
            padding: 10px;
        }